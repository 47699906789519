import React, { useContext, useState, useEffect } from "react"
import { format, addDays } from "date-fns"
import { sv } from "date-fns/locale"
import { QuoteContext } from "../../Context/QuoteContext"
import { navigate } from "gatsby"
import Layout from "../../components/Layout"
import styled from "@emotion/styled"
import SEO from "../../components/seo"
import { updateField, saveQuoteSendPin } from "../../Context/Actions"
import Loader from "../../components/GlobalAssets/Loader"
import { useTheme } from "emotion-theming"
import Icon from "../../components/Icon/Icon"
import ButtonLoader from "../../components/GlobalAssets/ButtonLoader"

const StartDatePage = props => {
  const { state, dispatch } = useContext(QuoteContext)
  const [checked, setChecked] = useState("2008-10-10")
  const [isSelected, setSelected] = useState(false)
  const theme = useTheme()

  useEffect(() => {
    if (state.fields.startDate.valid) {
      switch (state.quote.result.customerStatus) {
        case "new":
          navigate(`/bilforsakring/ange-mobilnummer`, { replace: true })
          break
        case "active":
          dispatch(saveQuoteSendPin({ state: state }))
          break
        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.fields.startDate.valid])

  if (!state.hasQuote) {
    if (typeof window === "undefined") {
      return null
    }
    navigate("/")
    return <Loader />
  }

  const selectDate = date => {
    setChecked(date)
    setSelected(true)

    dispatch(
      updateField({
        name: "startDate",
        value: date,
        valid: true,
        dirty: false,
      })
    )
  }

  const dateToday = format(new Date(), "yyyy-MM-dd")
  const dateTomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd")

  return (
    <Layout location={props.location}>
      <SEO title="evoli - Välj startdag för din bilförsäkring" noIndex={true} />

      <QuoteContainer>
        <QuoteHeroContent>
          <Icon
            name="calendar"
            bgColor={theme.colors.CardContainer.iconBackground}
            iconColor={theme.colors.CardContainer.iconColor}
            iconSize="25"
          />

          <h1>Ange startdag för bilförsäkringen</h1>
          <p>
            Bilförsäkringen kan börja gälla direkt eller på valfri dag i
            framtiden.
          </p>

          {state.quote.result.customerStatus !== "new" && (
            <h2>Välj datum för att genomföra köpet:</h2>
          )}

          <ButtonContainer>
            <DateButton
              selectedDate={
                !state.fields.startDate.dirty && checked === dateToday
                  ? true
                  : false
              }
              disabled={isSelected ? true : false}
              onClick={() => selectDate(dateToday)}
            >
              {isSelected && checked === dateToday ? (
                <ButtonLoader />
              ) : (
                <>
                  <ButtonTitle>
                    {format(new Date(), "'Idag,' iiii", { locale: sv })}
                  </ButtonTitle>
                  <ButtonDate>
                    {format(new Date(), "d MMMM", { locale: sv })}
                  </ButtonDate>
                </>
              )}
            </DateButton>

            <DateButton
              selectedDate={
                !state.fields.startDate.dirty && checked === dateTomorrow
                  ? true
                  : false
              }
              disabled={isSelected ? true : false}
              onClick={() => selectDate(dateTomorrow)}
            >
              {isSelected && checked === dateTomorrow ? (
                <ButtonLoader />
              ) : (
                <>
                  <ButtonTitle>
                    {format(addDays(new Date(), 1), "'Imorgon,' iiii", {
                      locale: sv,
                    })}
                  </ButtonTitle>
                  <ButtonDate>
                    {format(addDays(new Date(), 1), "d MMMM", {
                      locale: sv,
                    })}
                  </ButtonDate>
                </>
              )}
            </DateButton>

            <DateButton
              onClick={() =>
                navigate("/bilforsakring/lagg-till-startdag", { replace: true })
              }
              selectedDate={state.fields.startDate.dirty ? true : false}
              disabled={isSelected}
            >
              <ButtonTitle>
                {state.fields.startDate.dirty
                  ? state.fields.startDate.fieldValue
                  : "Välj datum"}
              </ButtonTitle>
            </DateButton>
          </ButtonContainer>
        </QuoteHeroContent>
      </QuoteContainer>
    </Layout>
  )
}
export default StartDatePage

const QuoteContainer = styled.section`
  background: ${props => (props.bgColor ? props.bgColor : "none")};
  width: 100%;
  padding: 40px 30px;
  position: relative;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  min-height: 570px;

  @media (max-width: 1024px) {
    margin: 0;
    text-align: left;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 50px 40px;
    margin: 0;
    text-align: left;
    flex-direction: column;
  }
  @media (max-width: 414px) {
    min-height: calc(100vh - 50px);
  }

  @media (max-width: 600px) and (min-width: 414px) {
    padding: 30px 20px;
    margin: 0;
    text-align: left;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (max-width: 375px) {
    padding: 30px 16px;
    margin: 0;
    text-align: left;
    flex-direction: column;
  }

  @media (max-width: 320px) {
    padding: 40px 15px;
    margin: 0;
    text-align: left;
    flex-direction: column;
  }
`

const QuoteHeroContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  h1,
  p {
    max-width: 305px;
  }

  h1 {
    margin: 0;
    margin-top: 10px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #ff4984;

    span {
      display: block;
      color: #5558ea;
      margin-top: 10px;
    }
  }

  h2 {
    margin: 0;
    margin-top: 25px;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #5558ea;
  }

  p {
    margin-top: 4px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
    text-align: center;
    color: #63638f;
    margin-bottom: 0;

    max-width: 240px;
  }
`

const ButtonTitle = styled.span`
  display: block;
  font-size: 14px;
`
const ButtonDate = styled.span`
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
`

const DateButton = styled.button`
  background: none;
  color: #01cc86;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  width: 70%;
  padding: 5px;
  min-height: 55px;
  border-radius: 3px;
  border: 1.3px solid #01cc86;
  transition: all 0.2s ease-in-out;
  width: 200px;
  margin-bottom: 10px;

  background: ${props => (props.selectedDate ? "#5558ea" : "none")};
  border-color: ${props => (props.selectedDate ? "#5558ea" : "#01cc86")};
  color: ${props => (props.selectedDate ? "#ffffff" : "#01cc86")};

  :disabled {
    cursor: default;
  }

  :last-of-type {
    margin-bottom: 0;
  }

  :hover {
    color: ${props => (props.selectedDate ? "#ffffff" : "#5558ea")};
    border-color: #5558ea;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
`
